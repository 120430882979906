import {Theme} from '@mui/material/styles/createTheme';

export default function TableRow(theme: Theme) {
    return {
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:first-of-type td': {
                        borderTop: 'none',
                    },
                    '&:last-child td': {
                        borderBottom: 'none',
                    },
                },
            }
        }
    };
}
