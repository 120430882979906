import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {DialogProps} from '@mui/material/Dialog/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Paper, {PaperProps} from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import {Breakpoint, SxProps} from '@mui/system';
import React from 'react';
import Draggable from 'react-draggable';
import {_t} from 'shared/lib/i18n/I18n';
import {DialogCloserIcon} from 'shared/ui/Icons/Icons';
import {colorBase, greyDefault} from 'shared/ui/themes/palette';
import {TransitionZoom} from 'shared/ui/transitions/transitions';

export const draggableID = 'draggable-dialog-title';

type PaperComponentProps = PaperProps & {
    draggable_id?: string
}

/**
 * !!! Has conflict with @dnd-kit : overlay out of area(react-draggable add css rule "transform: translate(0px)")
 */
function DialogDraggablePaperComponent(props: PaperComponentProps) {
    // If running in React Strict mode, ReactDOM.findDOMNode() is deprecated
    const nodeRef = React.useRef(null);

    return (
        <Draggable
            nodeRef={nodeRef}
            handle={'#' + (props?.draggable_id ?? draggableID)}
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper ref={nodeRef} {...props} />
        </Draggable>
    );
}

function DialogPaperComponent(props: PaperComponentProps) {
    // If running in React Strict mode, ReactDOM.findDOMNode() is deprecated
    const nodeRef = React.useRef(null);

    return <Paper ref={nodeRef} {...props} />
}


interface DialogueProps extends DialogProps {
    onConfirm: () => void;
    title: string;
    headerIcon?: any;
    loading: boolean;
    onClose: any;
    disableActions?: boolean;
    closeFromTitle?: boolean;
    maxWidth?: Breakpoint | false;
    id?: string;
    cancelBtnText?: string;
    submitBtnText?: string;
}

export default class Dialogue<T extends DialogueProps> extends React.Component<T, {}> {

    handleClose = () => {
        this.props.onClose();
    };

    render() {

        const sxDef = {alignItems: 'baseline', top: '-50%'};
        const {
            open,
            onConfirm,
            title,
            children,
            sx = {},
            loading = false,
            fullScreen = false,
            disableActions = false,
            closeFromTitle = false,
            headerIcon = undefined,
            maxWidth = 'xs',
            id = draggableID,
            cancelBtnText,
            submitBtnText
        } = this.props;

        return (
            <Dialog
                TransitionComponent={TransitionZoom}
                // PaperComponent={DialogDraggablePaperComponent}
                PaperComponent={DialogPaperComponent}
                PaperProps={{draggable_id: id, sx: {backgroundColor: greyDefault}}}
                // componentsProps={{backdrop: {invisible: true}}}
                // hideBackdrop={true}
                aria-labelledby={id}
                open={open}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick') return
                    this.handleClose();
                }} // !!! If exist when dialog is closed by backdrop click
                fullWidth={true}
                fullScreen={fullScreen}
                scroll='body'
                maxWidth={maxWidth}
                sx={Object.assign({}, sxDef, sx)}
            >
                <Grid container
                      sx={{
                          p: '4px',
                          pl: 1,
                          m: 0,
                          backgroundColor: colorBase.MainDark1,
                          color: colorBase.alabaster
                      }}
                >
                    <Grid
                        container
                        id={id}
                        xs={closeFromTitle ? 11 : 12}
                        // sx={{m: 0, p: 0, overflow: 'hidden', cursor: 'move'}}
                        sx={{m: 0, p: 0, overflow: 'hidden'}}
                    >
                        {
                            !headerIcon ? <></> :
                                <Grid xs={1} sx={{m: 0, p: 0}}>
                                    {headerIcon}
                                </Grid>
                        }

                        <Grid>
                            {_t(title)}
                        </Grid>
                    </Grid>

                    {
                        !closeFromTitle ? <></> :
                            <Grid
                                xs={1}
                                sx={{m: 0, p: 0, cursor: 'pointer'}}
                                onClick={this.handleClose}
                                container
                                direction='column'
                                justifyContent='flex-end'
                                alignItems='flex-end'
                            >
                                <DialogCloserIcon/>
                            </Grid>
                    }

                </Grid>

                {/*<Divider/>*/}

                <DialogContent
                    sx={{ // p:0 cause appear scroll in safari browser
                        margin: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingTop: 0,
                        paddingBottom: 1,
                    }}
                >
                    {children}
                </DialogContent>

                <DialogueActions
                    closeHandler={this.handleClose}
                    confirmHandler={onConfirm}
                    loading={loading}
                    disable={disableActions}
                    cancelBtnText={cancelBtnText}
                    submitBtnText={submitBtnText}
                />

            </Dialog>
        );
    }
}

interface DialogueActionsProps {
    confirmHandler?: () => void;
    closeHandler?: any;
    loading: boolean;
    disable?: boolean;
    closeBottomButtonDisable?: boolean;
    cancelBtnText?: string;
    submitBtnText?: string;
    sxContainer?: SxProps;
}

export const DialogueActions = (props: DialogueActionsProps) => {

    const {
        confirmHandler,
        closeHandler,
        loading,
        disable = false,
        closeBottomButtonDisable = false,
        cancelBtnText = 'Close',
        submitBtnText = 'Save',
        sxContainer
    } = props;

    if (disable) {
        return <></>;
    }

    return <Grid
        xs={12}
        container
        columnSpacing={10}
        direction='row'
        justifyContent='center'
        alignItems='center'
        sx={{m: 0, p: 0, mt: 1, mb: 2, ...sxContainer}}
    >

        {
            closeBottomButtonDisable ? <></> :
                <Grid>
                    <Button
                        color='secondary'
                        onClick={closeHandler}
                        // variant='text'
                        variant='outlined'
                        disabled={loading}
                        startIcon={<CancelIcon/>}
                    >
                        {_t(cancelBtnText)}
                    </Button>
                </Grid>
        }

        <Grid>
            <LoadingButton
                onClick={confirmHandler}
                loading={loading}
                loadingPosition='start'
                startIcon={<SaveIcon/>}
                // variant='text'
                variant='outlined'
            >
                {_t(submitBtnText)}
            </LoadingButton>
        </Grid>

    </Grid>;
};
