import {LocaleIdType}    from "features/BrandLocale/lib/BrandLocaleType";
import {CmsCategoryType} from "module/cms/lib/CmsCategoryType";
import {BrandKeyType}    from "shared/types/brand/BrandType";

export const cmsContentRouter = () => ({
    url: '/cms-content',
    get dynamic(): string[] {
        return ['brandId', 'categoryId']
    },
    get reactPath(): string {
        return this.url + '/' + this.dynamic.map(item => `:${item}`).join('/');
    },
    buildDataGridLinkPath(brandId: BrandKeyType, categoryId: CmsCategoryType): string {
        return `${this.url}/${brandId}/${categoryId}`
    }
})

export const cmsContentApiRouter = () => ({
    base: 'cms/content',
    get dataGrid(): string {
        return `${this.base}/data-grid`;
    },
    settingsDetails(aggregateKey: number, brandId: BrandKeyType, categoryId: CmsCategoryType): string {
        return `${this.base}/detail/${aggregateKey}/${brandId}/${categoryId}`
    },
    localeDetails(aggregateKey: number, localeId: LocaleIdType): string {
        return `${this.base}/locale-detail/${aggregateKey}/${localeId}`
    }
})
