import {Theme} from '@mui/material/styles/createTheme';

export default function Table(theme: Theme) {
    return {
        MuiTable: {
            styleOverrides: {
                root: {
                    border: 'none',
                }
            }
        }
    };
}
