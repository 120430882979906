import {presetPalettes} from '@ant-design/colors';
import {alpha, createTheme, getContrastRatio} from '@mui/material/styles';
import ThemeOption from 'shared/ui/themes/theme';


// Augment the palette to include a violet color
declare module '@mui/material/styles' {
    interface Palette {
        violet: Palette['primary'];
        blue: Palette['primary'];
    }

    interface PaletteOptions {
        violet?: PaletteOptions['primary'];
        blue?: PaletteOptions['primary'];
    }
}

// Update the Button's color options to include a violet option
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        violet: true;
        blue: true;
    }
}

export const ebonyClay = '#232e44';
export const blueZodiac = '#112a46';
// export const alabaster  = '#fafafa';
export const alabaster = '#d1e8fd';
const Red = '#e9534e';
const Black = '#000';
const White = '#fff';
const MainDark1 = '#011334';
const MainDark2 = '#3d80f3';
const MainDark3 = '#356ed09e';

const BlueBase = '#3d80f3';
const BlueLight = '#3D80F37F';
const BlueMain = alpha(BlueBase, 0.7);

const violetBase = '#7F00FF';
const violetMain = alpha(violetBase, 0.7);

export const TableBorder = '1px solid';
export const TableBorderColor = '#112A4663'; // From blueZodiac diff transparency

export const TableHeaderBgColor = '#011334E0';
export const TableHeaderColor = alabaster;
export const greyDefault = '#fafafb';
export const colorBase = {
    Black,
    White,
    ebonyClay,
    blueZodiac,
    alabaster,
    Red,
    MainDark1,
    MainDark2,
    MainDark3,
    BlueBase,
    BlueMain,
    BlueLight
};


const Palette = (mode: any) => {
    let colors = presetPalettes;
    const greyPrimary = [
        '#ffffff',
        '#fafafa',
        '#f5f5f5',
        '#f0f0f0',
        '#d9d9d9',
        '#bfbfbf',
        '#8c8c8c',
        '#595959',
        '#262626',
        '#141414',
        '#000000'
    ];
    const greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
    const greyConstant = [greyDefault, '#e6ebf1'];

    colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant];

    const paletteColor = ThemeOption(colors);

    return createTheme({
        palette: {
            mode,
            common: {
                black: Black,
                white: White
            },
            ...paletteColor,
            text: {
                primary: paletteColor.grey[700],
                secondary: paletteColor.grey[500],
                disabled: paletteColor.grey[400]
            },
            action: {
                disabled: paletteColor.grey[300]
            },
            divider: paletteColor.grey[200],
            background: {
                paper: paletteColor.grey[0],
                default: paletteColor.grey.A50,
                // default: paletteColor.grey["200"],
                // default: "rgba(1,19,52,0.14)",
                // default: greyDefault,
                // default: paletteColor.grey.A100
            },
            // https://v5.mui.com/material-ui/customization/palette/
            warning: {
                main: '#ed6c02',
                light: '#ff9800',
                dark: '#e65100'
            },
            // success: {
            //     // main: '#2e7d32',
            //     // light: '#4caf50',
            //     // dark: '#1b5e20',
            // },

            violet: {
                main: violetMain,
                light: alpha(violetBase, 0.5),
                dark: alpha(violetBase, 0.9),
                contrastText: getContrastRatio(violetMain, '#fff') > 4.5 ? '#fff' : '#111'
            },

            blue: {
                main: BlueMain,
                light: alpha(BlueBase, 0.5),
                dark: alpha(BlueBase, 0.9),
                contrastText: getContrastRatio(BlueMain, '#fff') > 2.5 ? '#fff' : '#111'
            }
        }
    });
};

export default Palette;
