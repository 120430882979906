import {Theme} from '@mui/material/styles/createTheme';
import {TableBorderColor} from 'shared/ui/themes/palette';

export default function TableCell(theme: Theme) {
    return {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderCollapse: 'collapse',
                    borderBottom: '1px solid ' + TableBorderColor,
                    borderRight: '1px solid ' + TableBorderColor,
                    '&:first-of-type': {
                        borderLeft: 'none',
                    },
                    '&:last-child': {
                        borderRight: 'none',
                    },
                },
                head: {
                    fontWeight: 600,
                },
                body: {
                    fontSize: 14,
                    whiteSpace: 'normal !important',
                    wordBreak: 'break-all',
                    lineHeight: 1.5
                }
            }
        }
    };
}
